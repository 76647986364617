export const updateReceivedFruitWeight = async id => {
  const weight = prompt('Enter the new weight in kg, e.g 21.23')
  if (weight) {
    const weightInGrams = Number(weight) * 1000
    const response = await fetch(`/api/fruit/received/${id}/weight`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(weightInGrams)
    })
    if (response.ok) {
      alert(`Received fruit updated: ${id}`)
    } else {
      alert(`Unable to update received fruit: ${response.statusText}`)
    }
  }

}

export const updateHarvestedCrateWeight = async (harvest, id) => {
  const weight = prompt('Enter the new weight in kg, e.g 21.23')
  if (weight) {
    const weightInGrams = Number(weight) * 1000
    const response = await fetch(`/api/fruit/harvests/${harvest}/crates/${id}/weight`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(weightInGrams)
    })
    if (response.ok) {
      alert(`Crate weight updated: ${id}`)
    } else {
      alert(`Unable to update crate weight: ${response.statusText}`)
    }
  }

}


