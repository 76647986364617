import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import Container from 'react-bootstrap/Container'
import { RecentHarvestDashboard } from './RecentHarvestDashboard'
import moment from 'moment'
import Row from 'react-bootstrap/Row'
import { numberToMonthString, numberToWeekString } from './HarvestGraph'

const urlSearchParams = new URLSearchParams(window.location.search)
export default class App extends React.Component {
  constructor (props) {
    super(props)
    const year = new Date().getFullYear()
    this.state = {
      customFrom: urlSearchParams.get('fromDate') || `${year}-01-01`,
      customTo: urlSearchParams.get('toDate') || `${year}-12-31`
    }
  }

  render () {
    const endOfMonth = moment().endOf('month')
    const thisWeek = moment().startOf('isoWeek')
    const lastWeek = moment().startOf('isoWeek').subtract(1, 'week')
    const thisMonth = moment().startOf('month')
    const lastMonth = moment().startOf('month').subtract(1, 'months')
    const endOfLastMonth = moment().startOf('month').subtract(1, 'months').endOf('month')

    return (
      <div className="App">
        <Container>
          <h1>W/C {thisWeek.format('DD MMM')}</h1>
          <Row>
            <RecentHarvestDashboard fromDate={thisWeek} days={7}/>
          </Row>
          <hr/>
          <h1>W/C {lastWeek.format('DD MMM')}</h1>
          <Row>
            <RecentHarvestDashboard fromDate={lastWeek} days={7}/>
          </Row>
          <hr/>
          <h1>{thisMonth.format('MMMM YYYY')}</h1>
          <Row>
            <RecentHarvestDashboard fromDate={thisMonth}
                                    toDate={endOfMonth}
                                    tickFormatter={numberToWeekString}
                                    grouping="week"/>
          </Row>
          <hr/>
          <h1>{lastMonth.format('MMMM YYYY')}</h1>
          <Row>
            <RecentHarvestDashboard fromDate={lastMonth}
                                    toDate={endOfLastMonth}
                                    tickFormatter={numberToWeekString}
                                    grouping="week"/>
          </Row>
          <hr/>
          <div>
            <label>
              <span>From Date</span>
              <input className="form-control"
                     type="date"
                     value={this.state.customFrom}
                     onChange={e => this.setState({ customFrom: e.target.value })}/>
            </label>
            <label>
              <span>To Date</span>
              <input className="form-control"
                     type="date"
                     value={this.state.customTo}
                     onChange={e => this.setState({ customTo: e.target.value })}/>
            </label>
            <RecentHarvestDashboard fromDate={moment(this.state.customFrom)}
                                    grouping="month"
                                    maxExpectedHarvest={1000000}
                                    tickFormatter={numberToMonthString}
                                    toDate={moment(this.state.customTo)}/>
            <hr/>
          </div>
        </Container>
      </div>
    )
  }
};
