let cache = null;

export const hasPermission = async permission => {
    if (cache) {
        return cache.includes(permission);
    }
    const response = await fetch('/api');
    if (!response.ok) {
        return false;
    }
    const {permissions} = await response.json();
    cache = permissions;
    return permissions.includes(permission);
}
