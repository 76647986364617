import React, { useCallback, useEffect, useRef, useState } from 'react'
import numeral from 'numeral'
import moment from 'moment'
import { Loader } from 'react-bootstrap-typeahead'
import { useNavigate } from 'react-router-dom'
import { hasPermission } from './permissions'
import { ChangePrice, GradeHarvest } from './EditHarvest'
import { Modal } from './Modal'

const now = moment().format('YYYY-MM-DD')
const urlSearchParams = new URLSearchParams(window.location.search)

const valueStyle = { textAlign: 'right', width: '150px', borderBottom: '1px solid black' }
const headerStyle = { fontWeight: '700', textAlign: 'right', width: '150px', borderBottom: '1px solid black' }

const GrowersList = ({ growers }) => {
  return (
    <datalist id="growers">
      {(growers || [])
        .map(v => (<option key={v._id} value={v._id}>{v.displayName}</option>))}
    </datalist>)
}

export const HarvestTable = ({ params, growers, villages, harvestTypes }) => {
  const [harvests, setHarvests] = useState([])
  const rawTypes = harvestTypes && harvestTypes.filter(s => s.value !== 'UNGRADED' && s.value !== 'GRADED')
  const [harvestTotal, setHarvestTotal] = useState({ totalHarvested: 0 })
  const [selectedHarvest, setSelectedHarvest] = useState()
  const [crates, setCrates] = useState([])
  const [canDelete, setCanDelete] = useState(false)
  const [canPay, setCanPay] = useState(false)
  const [modal, setModal] = useState(null)

  useEffect(() => {
    hasPermission('DELETE_HARVEST').then(setCanDelete)
    hasPermission('TRANSACTIONS').then(setCanPay)
  }, [setCanDelete, setCanPay])

  useEffect(() => {
    if (selectedHarvest) {
      setCrates([])
      fetch(`/api/fruit/harvests/${selectedHarvest}/crates`)
        .then(response => response.json())
        .then(crates => {
          setCrates(crates.sort((a, b) => {
            return a.timestamp - b.timestamp
          }))
        })
    }
  }, [selectedHarvest])

  const refresh = useCallback(async () => {
    if (!params) {
      return
    }
    const response = await fetch(`/api/fruit/harvests/search?${params.join('&')}`)

    const summaryHarvests = []
    const summaryHarvestMap = {}

    const newHarvests = await response.json()
    setHarvestTotal(newHarvests
      .filter(c => {
        if (c.parentId) {
          let summary = summaryHarvestMap[c.parentId]
          if (!summary) {
            summary = { ...c, _id: c.parentId + '-graded', type: 'GRADED', paymentStatus: 'N/A' }
            delete summary.parentId
            summaryHarvests.push(summary)
            summaryHarvestMap[c.parentId] = summary
          } else {
            summary.pricePerKilo = ''
            summary.source = ''
            summary.totalHarvested += c.totalHarvested
            summary.totalPrice += c.totalPrice
            summary.tax += c.tax
            summary.netPrice += c.netPrice
            summary.unionFees += c.unionFees
            summary.totalCrates += c.totalCrates
            summary.weightInKilos = numeral(summary.totalHarvested / 1000).format('0,0.000')
          }
        }
        return true
      })
      .reduce((a, c) => {
        a.totalHarvested += c.totalHarvested
        a.totalPrice += c.totalPrice
        a.tax += c.tax
        a.netPrice += c.netPrice
        a.unionFees += c.unionFees
        a.totalCrates += c.totalCrates
        a.paymentStatus[c.paymentStatus] += 1
        return a
      }, {
        totalHarvested: 0,
        totalCrates: 0,
        totalPrice: 0,
        unionFees: 0,
        netPrice: 0,
        tax: 0,
        paymentStatus: { PAID: 0, UNPAID: 0, PENDING: 0, NA: 0 }
      }))
    setHarvests([...newHarvests, ...summaryHarvests].sort((a, b) => a.date.localeCompare(b.date)))
  }, [params])

  useEffect(() => {
    refresh()
  }, [params, refresh])

  const updatePaymentStatus = async (harvest, value) => {
    const response = await fetch(`/api/fruit/harvests/${harvest._id}/payment-status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ paymentStatus: value, contractRefNumber: '', transactionRefNumber: '' })
    })
    if (response.ok) {
      refresh()
    } else {
      alert(`Error: ${response.statusText}`)
    }
  }

  const changeHarvestType = async (harvest, value) => {
    const response = await fetch(`/api/fruit/harvests/${harvest._id}/type`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ type: value })
    })
    if (response.ok) {
      refresh()
    } else {
      alert(`Error: ${response.statusText}`)
    }
  }

  const deleteHarvest = async harvest => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm('Are you sure you want to delete this harvest?')
    if (confirmed) {
      const response = await fetch(`/api/fruit/harvests/${harvest}`, {
        method: 'DELETE'
      })
      if (response.ok) {
        alert(`Harvest deleted: ${harvest}`)
      } else {
        alert(`Unable to delete harvest: ${response.statusText}`)
      }
      refresh()
    }
  }

  const exportToCsv = () => {
    const headerCsv = 'Date,Village,Grower,Total Harvested,# Crates,Price,Gross Cost,Withholding Tax,Net Cost,Union Fees,Type,Payment Status,Source'
    const csv = harvests.map(harvest => {
      return [
        moment(harvest.date).toISOString(),
        harvest.village,
        harvest.grower,
        `"${harvest.weightInKilos}"`,
        harvest.totalCrates,
        harvest.pricePerKilo,
        harvest.totalPrice,
        harvest.tax,
        harvest.netPrice,
        harvest.unionFees,
        harvest.type,
        harvest.paymentStatus,
        harvest.source
      ].join(',')
    }).join('\r\n')
    const blob = new Blob([headerCsv + '\r\n' + csv], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', 'harvests.csv')
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const closeAndRefresh = () => {
    setModal(null)
    refresh()
  }

  const gradeHarvest = async (harvest) => {
    setModal(<GradeHarvest harvest={harvest} onClose={closeAndRefresh}/>)
  }

  const changePrice = async (harvest) => {
    setModal(<ChangePrice harvest={harvest} onClose={closeAndRefresh}/>)
  }

  const columns = useRef([
    {
      label: 'Gross cost', value: harvest => numeral(harvest.totalPrice).format('0,0') || ''
    },
    // {
    //   label: 'Withholding tax', value: harvest => numeral(harvest.tax).format('0,0') || ''
    // },
    // {
    //   label: 'Net cost', value: harvest => numeral(harvest.netPrice).format('0,0') || ''
    // },
    // {
    //   label: 'Union fees', value: harvest => numeral(harvest.unionFees).format('0,0') || ''
    // },
    {
      label: 'Size', value: harvest => harvest.size || ''
    }
  ]).current

  const changeGrower = async (harvest) => {
    if (canDelete) {
      const grower = prompt('New grower id?')
      if (grower) {
        const result = await fetch(`/api/fruit/harvests/${harvest._id}/grower`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            grower
          })
        })
        if (!result.ok) {
          alert(`Unable to change harvest price: ${result.statusText}`)
        }
      }
    }
  }

  return (<>
    {modal && <Modal show children={modal}/>}
    <div className="row">
      <div className="col-sm-12">
        <button className="btn btn-outline-primary" onClick={exportToCsv}>Export</button>
      </div>
    </div>
    <table className="table table-striped table-hover">
      <thead>
      <tr>
        <th style={headerStyle}>Date</th>
        <th style={headerStyle}>Village</th>
        <th style={headerStyle}>Grower</th>
        <th style={headerStyle}>Total Harvested</th>
        <th style={headerStyle}># Crates</th>
        <th style={headerStyle}>Price</th>
        {columns.map((col, index) => (<th key={index} style={headerStyle}>{col.label}</th>))}
        <th style={headerStyle}>Type</th>
        <th style={headerStyle}>Payment Status</th>
        <th style={headerStyle}>Source</th>
      </tr>
      </thead>
      <tbody>
      <tr className="table-primary">
        <td style={headerStyle}>Total</td>
        <td style={valueStyle}/>
        <td style={valueStyle}/>
        <td style={headerStyle}>{numeral(harvestTotal.totalHarvested / 1000).format('0,0.000')}</td>
        <td style={headerStyle}>{harvestTotal.totalCrates}</td>
        <td style={valueStyle}/>
        {columns.map((col, index) => {
          return <td key={index} style={headerStyle}>{col.value(harvestTotal)}</td>
        })}
        <td style={valueStyle}/>
        <td style={valueStyle}>{harvestTotal.paymentStatus && Object.keys(harvestTotal.paymentStatus).map(s => {
          return <span className="pr-2" key={s}>{s}: {harvestTotal.paymentStatus[s]}</span>
        })}
        </td>
        <td style={valueStyle}/>
      </tr>
      {harvests.map(harvest => {
        const {
          grower,
          type,
          _id,
          source,
          paymentStatus,
          village: village1,
          pricePerKilo,
          totalCrates,
          weightInKilos,
          date
        } = harvest
        const villageObject = villages.find(v => v._id === village1)
        const villageName = villageObject ? villageObject.name : village1
        const growerObject = growers.find(s => s._id === grower)
        const displayName = growerObject ? growerObject.displayName : grower
        const paymentType = growerObject ? growerObject.paymentType : ''
        const ungraded = type === 'UNGRADED'
        const unpaid = paymentStatus !== 'PAID'
        const canChangePrice = canDelete && unpaid && type !== 'GRADED' && type !== 'UNGRADED'
        const canChangeType = canDelete && type !== 'GRADED' && type !== 'UNGRADED'
        return (<React.Fragment key={_id}>
            <tr className={type === 'GRADED' && 'table-info'}>
              <td style={valueStyle}>{moment(date).format('MMM DD, YYYY HH:mm')}</td>
              <td style={valueStyle}>{villageName}</td>
              <td style={valueStyle} onDoubleClick={() => changeGrower(harvest)}>{displayName}<br/>{paymentType}</td>
              <td style={valueStyle}>{weightInKilos}</td>
              <td style={valueStyle}>
                <button type="button"
                        className="btn btn-outline-secondary"
                        onClick={() => setSelectedHarvest(_id)}>{totalCrates}</button>
              </td>
              <td style={valueStyle}>
                {canChangePrice ?
                  <button className="btn btn-outline-secondary"
                          onClick={() => changePrice(harvest)}>{pricePerKilo}</button> : pricePerKilo}
              </td>
              {columns.map((col, index) => {
                return <td key={index} style={valueStyle}>{col.value(harvest)}</td>
              })}
              <td style={valueStyle}>
                {canDelete && ungraded ?
                  (<button className="btn btn-outline-secondary"
                           onClick={() => gradeHarvest(harvest)}>Ungraded</button>)
                  :
                  (rawTypes && canChangeType ?
                    (<select className="form-select"
                             onChange={e => changeHarvestType(harvest, e.target.value)}
                             value={type}>
                      {rawTypes.map(({ value }) => (<option value={value}>{value}</option>))}
                    </select>) : type)}
              </td>
              <td style={valueStyle}>
                {canPay ? <select className="form-select"
                                  disabled={type === 'GRADED'}
                                  onChange={e => updatePaymentStatus(harvest, e.target.value)}
                                  value={paymentStatus}>
                  <option value="UNPAID">Unpaid</option>
                  <option value="PENDING">Pending</option>
                  <option value="PAID">Paid</option>
                  <option value="NA">N/A</option>
                </select> : paymentStatus}
              </td>
              <td style={valueStyle}>{source}</td>
            </tr>
            {
              _id === selectedHarvest && <tr>
                <td/>
                <td colSpan={4}>
                  {crates.length > 0 ? <table className="table table-striped table-hover">
                    <thead>
                    <tr>
                      <th style={headerStyle}>Crate</th>
                      <th style={headerStyle}>Date</th>
                      <th style={headerStyle}>Weight</th>
                      <th style={headerStyle}>Received</th>
                    </tr>
                    </thead>
                    <tbody>
                    {crates.map(crate => {
                      return <tr key={crate._id}>
                        <td style={valueStyle}>{crate.crate}</td>
                        <td style={valueStyle}>{moment(crate.timestamp).format('MMM DD, YYYY HH:mm')}</td>
                        <td style={valueStyle}>{numeral(crate.weight / 1000).format('0,0.000')}</td>
                        <td style={valueStyle}>{crate.received ? moment(crate.received.timestamp).format('MMM DD, YYYY HH:mm') : ''}</td>
                      </tr>
                    })
                    }</tbody>
                  </table> : <Loader/>}</td>
                <td colSpan="7"/>
                <td>{canDelete &&
                  <button className="btn btn-danger" onClick={() => deleteHarvest(selectedHarvest)}>Delete
                    Harvest</button>}</td>
              </tr>
            }
          </React.Fragment>
        )
      })}
      </tbody>
    </table>
  </>)
}

const HarvestSearchInner = ({ villages, harvestTypes }) => {
  const navigate = useNavigate()
  const [harvestType, setHarvestType] = useState(urlSearchParams.get('harvestType') || '')
  const [paymentStatus, setPaymentStatus] = useState('')
  const [fromDate, setFromDate] = useState(urlSearchParams.get('fromDate') || now)
  const [toDate, setToDate] = useState(urlSearchParams.get('toDate') || now)
  const [grower, setGrower] = useState(urlSearchParams.get('grower'))
  const [growerPartial, setGrowerPartial] = useState(grower || '')
  const [village, setVillage] = useState(urlSearchParams.get('village'))
  const [villagePartial, setVillagePartial] = useState(village || '')
  const [params, setParams] = useState(null)
  const [growers, setGrowers] = useState([])

  useEffect(() => {
    fetch(`/api/growers?fromDate=${fromDate}`)
      .then(response => response.json())
      .then(setGrowers)
  }, [setGrowers, fromDate])

  const onGrowerChange = e => {
    const value = e.target.value
    setGrowerPartial(value)
    if (value.length === 0) {
      setGrower(value)
    } else if (/[0-9]{8}/.test(value)) {
      setGrower(value)
    }
  }
  const onVillageChange = e => {
    const value = e.target.value
    setVillagePartial(value)
    if (value.length === 0) {
      setVillage(value)
    } else if (/[0-9]{3}/.test(value)) {
      setVillage(value)
    }
  }

  useEffect(() => {
    const params = []
    if (village) {
      params.push(`village=${village}`)
    }
    if (grower) {
      params.push(`grower=${grower}`)
    }
    if (toDate) {
      params.push(`toDate=${toDate}`)
    }
    if (fromDate) {
      params.push(`fromDate=${fromDate}`)
    }
    if (harvestType) {
      params.push(`harvestType=${harvestType}`)
    }
    if (paymentStatus) {
      params.push(`paymentStatus=${paymentStatus}`)
    }
    navigate(`/harvests?${params.join('&')}`)
    setParams(params)
  }, [fromDate, grower, harvestType, navigate, paymentStatus, setParams, toDate, village])

  return (
    <div style={{ padding: '5px' }}><h1>Harvest Search</h1>
      <GrowersList growers={growers}/>
      <div className="row">
        <div className="col-sm-12">
          <label>
            <span>From Date</span>
            <input className="form-control"
                   type="date"
                   value={fromDate}
                   onChange={e => setFromDate(e.target.value)}/>
          </label>
          <label>
            <span>To Date</span>
            <input className="form-control"
                   type="date"
                   value={toDate}
                   onChange={e => setToDate(e.target.value)}/>
          </label>
          <label>
            <span>Village</span>
            <input className="form-control"
                   list="villages"
                   value={villagePartial}
                   onChange={e => onVillageChange(e)}/>
            <datalist id="villages">
              {villages.map(v => (<option key={v._id} value={v._id}>{v.name}</option>))}
            </datalist>
          </label>
          <label>
            <span>Grower</span>
            <input className="form-control"
                   list="growers"
                   value={growerPartial}
                   onChange={e => onGrowerChange(e)}/>
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-8">
          <label>
            <span>Payment Status</span>
            <select className="form-select"
                    value={paymentStatus}
                    onChange={e => setPaymentStatus(e.target.value)}>
              <option value={''}>Any Payment status</option>
              <option value="PAID">Paid</option>
              <option value="PENDING">Pending</option>
              <option value="UNPAID">Unpaid</option>
              <option value="NA">N/A</option>
            </select>
          </label>
          <label>
            <span>Harvest Type</span>
            <select className="form-select"
                    value={harvestType}
                    onChange={e => setHarvestType(e.target.value)}>
              <option value={''}>All {harvestTypes.length} types</option>
              {harvestTypes.map(v => (<option key={v.value} value={v.value}>{v.label}</option>))}
            </select>
          </label>
        </div>
      </div>
      <HarvestTable params={params} growers={growers} villages={villages} harvestTypes={harvestTypes}/>
    </div>
  )
}

export const HarvestSearch = () => {
  const [harvestTypes, setHarvestTypes] = useState([])
  const [villages, setVillages] = useState([])

  useEffect(() => {
    fetch('/api/villages')
      .then(response => response.json())
      .then(setVillages)
  }, [setVillages])
  useEffect(() => {
    fetch('/api/fruit/harvests/types')
      .then(response => response.json())
      .then(setHarvestTypes)
  }, [setHarvestTypes])
  return (<React.Fragment>
    <HarvestSearchInner
      villages={villages}
      harvestTypes={harvestTypes}/>
  </React.Fragment>)
}
