import HarvestGraph, { numberToDateString } from './HarvestGraph'
import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import Statistics from './Statistics'
import moment from 'moment'
import Row from 'react-bootstrap/Row'
import PropTypes from 'prop-types'

export const RecentHarvestDashboard = ({
  excludeReceived,
  grower,
  toDate,
  tickFormatter,
  maxExpectedHarvest,
  days,
  fromDate,
  grouping
}) => {

  const [totalHarvested, setTotalHarvested] = useState()
  const [totalExported, setTotalExported] = useState()
  const [exportUrl, setExportUrl] = useState()

  useEffect(() => {
    const from = fromDate.format('YYYY-MM-DD')
    const to = (toDate || moment(fromDate).add(days, 'days')).format('YYYY-MM-DD')
    const queries = []
    queries.push(`fromDate=${from}`)
    queries.push(`toDate=${to}`)
    queries.push(`grouping=${grouping || 'dayOfYear'}`)

    if (grower) {
      queries.push(`grower=${grower}`)
    }

    const queryParams = queries.join('&')
    setExportUrl(`/api/fruit/harvests/export.csv?${queryParams}`)
    fetch(`/api/fruit/crates/totalWeight?${queryParams}`)
      .then(response => response.json())
      .then(data => {
        if (data.length) {
          setTotalHarvested(data[0].data)
        } else {
          setTotalHarvested([])
        }
      })

    if (!excludeReceived) {
      fetch(`/api/fruit/exported/totalWeight?${queryParams}`)
        .then(response => response.json())
        .then(data => {
          if (data.length) {
            setTotalExported(data[0].data)
          } else {
            setTotalExported([])
          }
        })
    }
  }, [days, toDate, fromDate, grouping, excludeReceived, grower, setTotalHarvested, setExportUrl, setTotalExported])

  return (
    <>
      <Row>
        <Col>
          <HarvestGraph
            harvested={totalHarvested}
            exported={totalExported}
            maxY={maxExpectedHarvest || 30000}
            tickFormatter={tickFormatter || numberToDateString}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <a href={exportUrl}>Export</a>
          <Statistics grower={grower}
                      fromDate={fromDate}
                      toDate={toDate}
                      days={days}/>
        </Col>
      </Row>
    </>
  )
}

RecentHarvestDashboard.propTypes = {
  fromDate: PropTypes.object.isRequired,
  toDate: PropTypes.object,
  grower: PropTypes.string,
  grouping: PropTypes.string,
  excludeReceived: PropTypes.bool
}
