import React from 'react'
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom'
import App from './App'

import { VillageMap } from './VillageMap'
import { VillageBreakdown } from './VillageBreakdown'
import { VillageDetail } from './VillageDetail'
import { HarvestSearch } from './HarvestSearch'
import { OilSacks } from './OilSacks'
import { GrowerDetail, Growers } from './Growers'
import { Banking } from './Banking'
import { ReceivedHarvests } from './ReceivedHarvests'
import { Crates } from './Crates'
import { Users } from './Users'

export const AppRoutes = () => {
    return (
      <BrowserRouter>
          <div>
              <h1 style={{ paddingTop: '15px', display: 'flex' }}>
                  <div>
                      <img alt="" src="/logo.svg"/> Kuza Africa
                      &nbsp;
                      <Link to="/">Home</Link>&nbsp;|&nbsp;
                      <Link to="/villages">Villages</Link>&nbsp;|&nbsp;
                      <Link to="/growers">Farmers</Link>&nbsp;|&nbsp;
                      <Link to="/harvests">Harvests</Link>&nbsp;|&nbsp;
                      <Link to="/crates">Crates</Link>&nbsp;|&nbsp;
                      <Link to="/received">Packshed</Link>&nbsp;|&nbsp;
                      <Link to="/oil">Oil</Link>&nbsp;|&nbsp;
                      <Link to="/banking">Payments</Link>
                  </div>
              </h1>
              <Routes>
                  <Route path="/map" element={<VillageMap/>}/>
                  <Route path="/growers/:grower" element={<GrowerDetail/>}/>
                  <Route path="/growers" element={<Growers/>}/>
                  <Route path="/villages/:village" element={<VillageDetail/>}/>
                  <Route path="/villages" element={<VillageBreakdown/>}/>
                  <Route path="/harvests" element={<HarvestSearch/>}/>
                  <Route path="/received" element={<ReceivedHarvests/>}/>
                  <Route path="/crates" element={<Crates/>}/>
                  <Route path="/oil" element={<OilSacks/>}/>
                  <Route path="/banking" element={<Banking/>}/>
                  <Route path="/users" element={<Users/>}/>
                  <Route path="/" element={<App/>}/>
              </Routes>
          </div>
        </BrowserRouter>
    )
}
