import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import numeral from 'numeral'

const now = moment().format('YYYY-MM-DD')
const urlSearchParams = new URLSearchParams(window.location.search)

const valueStyle = { textAlign: 'right', width: '150px', borderBottom: '1px solid black' }
const headerStyle = { textAlign: 'right', width: '150px', borderBottom: '1px solid black' }

export const VillageDetail = () => {
  const { village } = useParams()
  const navigate = useNavigate()
  const [villageObject, setVillage] = useState(null)
  const [receivedFruit, setReceivedFruit] = useState([])
  const [fromDate] = useState(urlSearchParams.get('fromDate') || now)
  const [toDate] = useState(urlSearchParams.get('toDate') || now)

  useEffect(() => {
    fetch(`/api/villages/${village}`)
      .then(response => response.json())
      .then(setVillage)

    navigate(`/villages/${village}?toDate=${toDate}&fromDate=${fromDate}`)
    fetch(`/api/fruit/received?village=${village}&toDate=${toDate}&fromDate=${fromDate}`)
      .then(response => response.json())
      .then(setReceivedFruit)
  }, [fromDate, navigate, toDate, village])

  return (villageObject && <section>
    <h3>{villageObject.name}</h3>
    <h4>Prices</h4>
    {villageObject.prices ? (<table className="table table-striped table-hover">
      <thead>
      <tr>
        <th>Type</th>
        <th>Price</th>
      </tr>
      </thead>
      <tbody>
      {Object.keys(villageObject.prices).map(type => {
        const price = villageObject.prices[type]
        return (
            <tr key={type}>
              <td>{type}</td>
              <td>{price}</td>
            </tr>
        )
      })}
      </tbody>
    </table>) : <div><strong>Price per Kilo:</strong> {villageObject.fruitPricePerKilo}</div>}
    <h4>Received Fruit</h4>
    <table className="table table-striped table-hover">
      <thead>
      <tr>
        <th style={headerStyle}>Date</th>
        <th style={headerStyle}>Received (kg)</th>
        <th style={headerStyle}>Difference (kg)</th>
        <th style={headerStyle}>Weighed crates</th>
        <th style={headerStyle}>Matched crates</th>
      </tr>
      </thead>
      <tbody>
      {receivedFruit.map(({ _id, date, matchedCrates, weighedCrates, weight, weightDifference }) => {
        return (
          <tr key={_id} className={weightDifference > 5000 ? 'table-danger' : null}>
            <td style={valueStyle}>{moment(date).format('MMM DD, YYYY HH:mm')}</td>
            <td style={valueStyle}>{numeral(weight / 1000).format('0,0.000')}</td>
            <td style={valueStyle}>{numeral(weightDifference / 1000).format('0,0.000')}</td>
            <td style={valueStyle}>{weighedCrates.length}</td>
            <td style={valueStyle}>{matchedCrates.length}</td>
          </tr>
        )
      })}
      </tbody>
    </table>
  </section>)
}
