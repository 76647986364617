import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from 'react-bootstrap-typeahead'
import moment from 'moment'
import { RecentHarvestDashboard } from './RecentHarvestDashboard'
import GoogleMapReact from 'google-map-react'
import { HarvestTable } from './HarvestSearch'

export const Growers = () => {

  const [growers, setGrowers] = useState([])
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'year').format('YYYY-MM-DD'))
  const [allFarmers, setAllFarmers] = useState(false)

  useEffect(() => {
    const url = allFarmers ? `/api/growers` : `/api/growers?fromDate=${fromDate}`
    fetch(url)
      .then(response => response.json())
      .then(growers => growers.sort((a, b) => a.displayName.localeCompare(b.displayName)))
      .then(setGrowers)
  }, [fromDate, allFarmers])

  const exportToCsv = () => {
    const headerCsv = 'ID,Name,Last Harvest,Phone,Payment Type, Payment Account'
    const csv = growers.map(farmer => {
      return [farmer._id,
        farmer.displayName,
        farmer.lastHarvest ? moment(farmer.lastHarvest).toISOString() : '',
        farmer.phone,
        farmer.paymentType,
        farmer.paymentAccount].join(',')
    }).join('\r\n')
    const blob = new Blob([headerCsv + '\r\n' + csv], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', 'farmers.csv')
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const headerStyle = { textAlign: 'left', width: '150px', borderBottom: '1px solid black' }

  return <div>
    <div style={{ padding: '5px' }}><h1>Farmer Search</h1>
      <div className="row">
        <div className="col-auto">
          <label>
            <span className="form-check-label">All farmers</span>
            <input
              className="form-check"
              checked={allFarmers}
              type="checkbox" onClick={() => setAllFarmers(!allFarmers)}/>
          </label>
        </div>
        <div className="col-auto">
          <label>
            <span>Last Harvest from date</span>
            <input className="form-control"
                   disabled={allFarmers}
                   type="date"
                   value={fromDate}
                   onChange={e => setFromDate(e.target.value)}/>
          </label>
        </div>
        <div className="col-auto mt-4">
          <button className="btn btn-outline-primary" onClick={exportToCsv}>Export</button>
        </div>
      </div>
    </div>
    <table className="table table-striped table-hover">
      <thead>
      <tr>
        <th style={headerStyle}>
          ID
        </th>
        <th style={headerStyle}>
          Name
        </th>
        <th style={headerStyle}>
          # Trees
        </th>
        <th style={headerStyle}>
          Orchard age
        </th>
        <th style={headerStyle}>
          Payment Type
        </th>
        <th style={headerStyle}>
          Payment Account
        </th>
        <th style={headerStyle}>Location</th>
        <th style={headerStyle}>Phone</th>
        <th style={headerStyle}>Type</th>
        <th style={headerStyle}>Last Harvest</th>
      </tr>
      </thead>
      <tbody>
      {growers.map(grower => {
        return <tr key={grower._id}>
          <td>{grower._id}</td>
          <td><a href={`/growers/${grower._id}`}>{grower.displayName}</a></td>
          <td>{grower.numberOfTrees}</td>
          <td>{grower.orchardAge}</td>
          <td>{grower.paymentType}</td>
          <td>{grower.paymentAccount}</td>
          <td>{grower.location && grower.location.coordinates.join(',')}</td>
          <td>{grower.phone}</td>
          <td>{grower.type}</td>
          <td>{grower.lastHarvest ? moment(grower.lastHarvest).format('DD MMM YYYY') : ''}</td>
        </tr>
      })}
      </tbody>
    </table>
  </div>
}

export const GrowerLocation = ({ grower }) => {
  return <div className="badge badge-info badge-pill">{grower}</div>
}

export const GrowerDetail = () => {
  const [growerDetail, setGrowerDetail] = useState(null)
  const [paymentTypes, setPaymentTypes] = useState([])
  const { grower } = useParams()

  const refreshGrowerDetail = useCallback(() => {
    fetch(`/api/growers/${grower}`)
      .then(response => response.json())
      .then(setGrowerDetail)
  }, [grower, setGrowerDetail])

  useEffect(() => {
    fetch('/api/payment-types')
      .then(response => response.json())
      .then(setPaymentTypes)

    refreshGrowerDetail()
  }, [refreshGrowerDetail])
  if (!growerDetail) {
    return <Loader/>
  }

  const updateGrowerProperty = (property, value) => {
    setGrowerDetail({ ...growerDetail, [property]: value })
  }

  const updateGrower = async () => {
    const response = await fetch(`/api/growers/${growerDetail._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(growerDetail)
    })
    if (!response.ok) {
      alert('Unable to update grower: ' + response.statusText)
    } else {

    }
  }

  const fromDate = moment(`2018-01-01`)
  const toDate = moment(`${new Date().getFullYear()}-12-31`)
  return <div className="container">
    <h2>{growerDetail.displayName}</h2>
    <h3>Details</h3>
    <div className="row">
      <div className="col">
        <div className="mb-3">
          <label className="form-label">Phone</label>
          <input type="tel" className="form-control"
                 onChange={e => updateGrowerProperty('phone', e.target.value)}
                 value={growerDetail.phone}/>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <div className="mb-3">
          <label className="form-label">Payment Type</label>
          <select className="form-control"
                  onChange={e => updateGrowerProperty('paymentType', e.target.value)}
                  value={growerDetail.paymentType}>
            {paymentTypes.map(({ value, label }) => {
              return <option value={value}>{label}</option>
            })}
          </select>
        </div>
      </div>
      <div className="col">
        <div className="mb-3">
          <label className="form-label">Payment Account</label>
          <input type="text" className="form-control"
                 onChange={e => updateGrowerProperty('paymentAccount', e.target.value)}
                 value={growerDetail.paymentAccount}/>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <div className="mb-3">
          <label className="form-label">Farmer Type</label>
          <select className="form-select"
                  onChange={e => updateGrowerProperty('type', e.target.value)}
                  value={growerDetail.type}>
            <option value="EXTERNAL">External</option>
            <option value="INTERNAL">Internal</option>
            <option value="SISTER">Sister</option>
          </select>
        </div>
        <div className="mb-3">
          <button className="btn btn-outline-primary" onClick={updateGrower}>Update</button>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <h3>Harvest Yield</h3>
        <RecentHarvestDashboard grouping="year"
                                tickFormatter={value => value}
                                excludeReceived fromDate={fromDate} toDate={toDate} grower={grower}/>
      </div>
    </div>
    <HarvestTable params={[`grower=${grower}`]}
                  villages={[]}
                  growers={[growerDetail]}/>
    {growerDetail.location && <div className="row">
      <div className="col">
        <h3>Location</h3>
        <div style={{ height: '50vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyCVfznYo0wbEmJ8QEOKK3xjPvO75VseDqg' }}
            center={{ lat: growerDetail.location.coordinates[1], lng: growerDetail.location.coordinates[0] }}
            defaultZoom={11}
          >
            <GrowerLocation
              key={growerDetail._id}
              lat={growerDetail.location.coordinates[1]}
              lng={growerDetail.location.coordinates[0]} grower={grower}
            />
          </GoogleMapReact>
        </div>
      </div>
    </div>}
  </div>
}
