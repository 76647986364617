import React, { useCallback, useEffect, useState } from 'react'
import numeral from 'numeral'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { hasPermission } from './permissions'
import { updateHarvestedCrateWeight, updateReceivedFruitWeight } from './actions'

const now = moment().format('YYYY-MM-DD')
const urlSearchParams = new URLSearchParams(window.location.search)

const labelStyle = { textAlign: 'right', width: '200px', fontWeight: 'bold' }
const valueStyle = { textAlign: 'right', width: '150px' }
const headerStyle = { textAlign: 'right', width: '150px' }

export const Crates = () => {
  const [harvests, setHarvests] = useState({})
  const [cratesCount, setCrateCount] = useState({})
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const [showMissingOnly, setShowMissingOnly] = useState(false)
  const [crate, setCrate] = useState(urlSearchParams.get('crate'))
  const [grower, setGrower] = useState(urlSearchParams.get('grower'))
  const [village, setVillage] = useState(urlSearchParams.get('village'))
  const [harvester, setHarvester] = useState(urlSearchParams.get('harvester'))
  const [fromDate, setFromDate] = useState(urlSearchParams.get('fromDate') || now)
  const [toDate, setToDate] = useState(urlSearchParams.get('toDate') || now)
  const [canDelete, setCanDelete] = useState(false)

  useEffect(() => {
    hasPermission('DELETE_HARVEST').then(setCanDelete)
  }, [setCanDelete])

  const refresh = useCallback(async () => {
    const params = []
    if (toDate) {
      params.push(`toDate=${toDate}`)
    }
    if (fromDate) {
      params.push(`fromDate=${fromDate}`)
    }
    if (crate) {
      params.push(`crate=${crate}`)
    }
    if (grower) {
      params.push(`grower=${grower}`)
    }
    if (village) {
      params.push(`village=${village}`)
    }
    if (harvester) {
      params.push(`harvester=${harvester}`)
    }
    navigate(`/crates?${params.join('&')}`)
    const crateResponse = await fetch(`/api/fruit/crates?${params.join('&')}`)
    const crates = await crateResponse.json()
    setData(crates.sort((a, b) => a.timestamp - b.timestamp))
    const harvestResponse = await fetch(`/api/fruit/harvests?${params.join('&')}`)
    setHarvests((await harvestResponse.json()).reduce((a, c) => {
      a[c._id] = c
      return a
    }, {}))
    const duplicates = crates.reduce((a, c) => {
      a[c.crate] = (a[c.crate] || 0) + 1
      return a
    }, {})
    setCrateCount(duplicates)
  }, [village, harvester, grower, crate, fromDate, navigate, toDate])

  useEffect(() => refresh(), [refresh])

  const allCrates = data
    .filter(datum => !showMissingOnly || !datum.received)
  const filteredCrates = allCrates.filter(s => {
    if (!harvester) {
      return true
    }
    const harvest = harvests[s.harvest]
    return harvest && harvest.source === harvester
  })
  const headerRow = filteredCrates.reduce((a, c) => {
    a.weight += c.weight
    a.received += c.received ? c.received.weight : 0
    return a
  }, { weight: 0, received: 0 })

  const exportToCsv = () => {
    const headerCsv = 'Date,Crate,Village,Grower,Weight,Received Time, Received Weight,Harvester'
    const csv = filteredCrates.map(crate => {
      return [
        crate.date,
        crate.crate,
        crate.village,
        crate.grower,
        crate.weight,
        crate.received ? moment(crate.received.timestamp).toISOString() : '',
        crate.received ? crate.received.weight : '',
        harvests[crate.harvest] ? harvests[crate.harvest].source : ''
      ].join(',')
    }).join('\r\n')
    const blob = new Blob([headerCsv + '\r\n' + csv], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', 'crates.csv')
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const updateWeight = async id => {
    await updateReceivedFruitWeight(id)
    await refresh()
  }
  const updateCrateWeight = async (harvest, id) => {
    await updateHarvestedCrateWeight(harvest, id)
    await refresh()
  }

  return (
    <section>
      <h2>Crates</h2>
      <div className="row">
        <div className="col-sm-12 padded">
          <label>
            <span>Hide received crates</span>
            <input className="form-check-input"
                   type="checkbox"
                   value={showMissingOnly}
                   checked={showMissingOnly}
                   onChange={() => setShowMissingOnly(!showMissingOnly)}/>
          </label>
          <label>
            <span>From Date</span>
            <input className="form-control"
                   type="date"
                   value={fromDate}
                   onChange={e => setFromDate(e.target.value)}/>
          </label>
          <label>
            <span>To Date</span>
            <input className="form-control"
                   type="date"
                   value={toDate}
                   onChange={e => setToDate(e.target.value)}/>
          </label>
          <label>
            <span>Crate #</span>
            <input className="form-control"
                   type="number"
                   value={crate}
                   onChange={e => setCrate(e.target.value)}/>
          </label>
          <label>
            <span>Grower #</span>
            <input className="form-control"
                   type="number"
                   value={grower}
                   onChange={e => setGrower(e.target.value)}/>
          </label>
          <label>
            <span>Village #</span>
            <input className="form-control"
                   type="number"
                   value={village}
                   onChange={e => setVillage(e.target.value)}/>
          </label>
          <label>
            <span>Harvester #</span>
            <input className="form-control"
                   type="number"
                   value={harvester}
                   onChange={e => setHarvester(e.target.value)}/>
          </label>
          <button className="btn btn-outline-primary" onClick={exportToCsv}>Export</button>
        </div>
      </div>
      <table className="table table-striped table-hover">
        <thead>
        <tr>
          <th style={headerStyle}>Date</th>
          <th style={headerStyle}>Crate</th>
          <th style={headerStyle}>Village</th>
          <th style={headerStyle}>Grower</th>
          <th style={headerStyle}>Total Harvested (kg)</th>
          <th style={headerStyle}>Received</th>
          <th style={headerStyle}>Transit Time</th>
          <th style={headerStyle}>Received (kg)</th>
          <th style={headerStyle}>Harvester</th>
          <th style={headerStyle}>Reused Crate?</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td className={headerStyle}/>
          <td className={headerStyle}/>
          <td className={headerStyle}/>
          <td className={headerStyle}/>
          <td style={valueStyle}>{numeral(headerRow.weight / 1000).format('0,0.000')}</td>
          <td className={headerStyle}/>
          <td className={headerStyle}/>
          <td style={valueStyle}>{numeral(headerRow.received / 1000).format('0,0.000')}</td>
          <td className={headerStyle}/>
          <td className={headerStyle}/>
        </tr>
        {filteredCrates
          .map(datum => {
            const harvestTime = moment(datum.timestamp)
            const receivedTime = datum.received && moment(datum.received.timestamp)
            const harvest = harvests[datum.harvest]
            const isDuplicate = cratesCount[datum.crate] > 1
            const now = moment.now()
            const receivedWeight = datum.received && numeral(datum.received.weight / 1000).format('0,0.000')
            const harvestedWeight = numeral(datum.weight / 1000).format('0,0.000')
            return (
              <tr key={datum._id} className={!datum.received ? 'table-danger' : ''}>
                <td style={valueStyle}>{harvestTime.format('MMM DD, YYYY HH:mm')}</td>
                <td style={valueStyle}>{datum.crate}</td>
                <td style={labelStyle}>{datum.village}</td>
                <td style={labelStyle}>{datum.grower}</td>
                <td style={valueStyle}>
                  {canDelete ? <button
                    onClick={() => updateCrateWeight(datum.harvest, datum._id)}
                    className="btn btn-outline-info">{harvestedWeight}</button> : harvestedWeight}
                </td>
                <td style={valueStyle}>{datum.received && receivedTime.format('MMM DD, YYYY HH:mm')}</td>
                <td style={valueStyle}>{datum.received ? harvestTime.from(receivedTime, true) : harvestTime.from(now, true)}</td>
                <td style={valueStyle}>
                  {canDelete && datum.received ? <button
                    onClick={() => updateWeight(datum.received.timestamp)}
                    className="btn btn-outline-info">{receivedWeight}</button> : receivedWeight}
                </td>
                <td style={valueStyle}>{harvest && harvest.source}</td>
                <td style={valueStyle}>{isDuplicate ? 'REUSED' : ''}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </section>
  )
}
