import React from 'react'
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts'
import moment from 'moment'
import numeral from 'numeral'

export const numberToMonthString = function (value) {
  return moment().month(value - 1).format('MMM')
}
export const numberToWeekString = function (value) {
  return 'W' + value
}

export const numberToDateString = function (value) {
  return moment().dayOfYear(value).format('DD/MM')
}

function HarvestGraph ({ harvested, exported, tickFormatter, maxY = 800000 }) {
  const dateMap = new Map()
  const data = []

  const newDatum = date => {
    if (dateMap.has(date)) {
      return dateMap.get(date)
    } else {
      const datum = { date, totalExported: 0, totalHarvested: 0, totalReceived: 0 }
      data.push(datum)
      dateMap.set(date, datum)
      return datum
    }
  }

  harvested && harvested
    .forEach(harvest => {
      const { date, totalHarvested, totalReceived } = harvest
      const datum = newDatum(date)
      datum.totalHarvested = totalHarvested
      datum.totalReceived = totalReceived
    })
  exported && exported
    .forEach(harvest => {
      const { date, totalExported } = harvest
      const datum = newDatum(date)
      datum.totalExported = totalExported
    })

  return (
    <BarChart
      width={1280}
      height={300}
      data={data.sort((a, b) => a.date - b.date)}
      margin={{
        top: 20, right: 10, left: 30, bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3"/>
      <XAxis dataKey="date" tickFormatter={value => tickFormatter(value)}/>
      <YAxis
        domain={[0, maxY]} unit="kg"
        tickFormatter={value => numeral(value).format('0,0')}
      />
      <Tooltip
        labelFormatter={value => tickFormatter(value)}
        formatter={value => numeral(value).format('0,0') + 'kg'}
      />
      <Legend/>
      {harvested && <Bar dataKey="totalHarvested" name="Harvested" fill="#558303"/>}
      {harvested && <Bar dataKey="totalReceived" name="Received" fill="#C15C37"/>}
      {exported && <Bar dataKey="totalExported" name="Exported" fill="#310383"/>}
    </BarChart>
  )
}

export default HarvestGraph
