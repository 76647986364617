import React, {useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {hasPermission} from "./permissions";
import moment from "moment";

const valueStyle = {textAlign: 'right', width: '150px', borderBottom: '1px solid black'}
const headerStyle = {textAlign: 'right', width: '150px', borderBottom: '1px solid black'}

const labelStyle = {
    textAlign: 'right',
    width: '200px',
    padding: '5px',
    fontWeight: 'bold',
    borderBottom: '1px solid black'
}

export const VillageBreakdown = () => {
    const [data, setData] = useState([])
    const [newVillageName, setNewVillageName] = useState('')
    const [regions, setRegions] = useState([])
    const [harvestTypes, setHarvestTypes] = useState([])
    const [hasVillagePermission, setHasVillagePermission] = useState('')

    const refresh = useCallback(async () => {
        const response = await fetch(`/api/villages`)
        setData(await response.json())
    }, [setData]);

    useEffect(() => {
        fetch('/api/fruit/harvests/types')
            .then(response => response.json())
            .then(setHarvestTypes)
    }, [])
    useEffect(() => {
        fetch("/api/regions")
            .then(response => response.json())
            .then(setRegions);
    }, [setRegions])

    useEffect(() => {
        hasPermission('ADD_VILLAGE').then(setHasVillagePermission);
        refresh()
    }, [refresh, setHasVillagePermission])

    const createNewVillage = async () => {
        const response = await fetch('/api/villages', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name: newVillageName})
        })
        if (response.ok) {
            const village = await response.json();
            alert('Village created: ' + village._id);
            refresh();
        }
    }

    const updateRegion = async (village, region) => {
        const newVillage = {...village, region}
        const response = await fetch(`/api/villages/${village._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newVillage)
        })
        if (response.ok) {
            refresh();
        }
    }

    return (
        <>
            <section>
                <h3>Regions</h3>
                <table>
                    <thead>
                    <tr>
                        <th style={headerStyle}>Name</th>
                        <th style={headerStyle}>Withholding Tax (%)</th>
                        <th style={headerStyle}>Village count</th>
                        <th style={headerStyle}>Union</th>
                        <th style={headerStyle}>Payment account</th>
                        <th style={headerStyle}>Payment type</th>
                        <th style={headerStyle}>Union Fees (Tsh/kg)</th>
                        <th style={headerStyle}>Fixed Fruit Price (Tsh/kg)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {regions.map(r => {
                        const villageCount = data.filter(d => d.region === r.name).length
                        return <tr key={r.name}>
                            <td style={labelStyle}>{r.name}</td>
                            <td style={valueStyle}>{r.withholdingTax}</td>
                            <td style={valueStyle}>{villageCount}</td>
                            <td style={valueStyle}>{r.union && r.union.name}</td>
                            <td style={valueStyle}>{r.union && r.union.paymentAccount}</td>
                            <td style={valueStyle}>{r.union && r.union.paymentType}</td>
                            <td style={valueStyle}>{r.union && r.union.unionFeesPerKilo && Object.keys(r.union.unionFeesPerKilo).map(s => {
                                return <span className="pr-2" key={s}>{s}: {r.union.unionFeesPerKilo[s]}</span>
                            })}</td>
                            <td style={valueStyle}>{r.union && r.union.fixedFruitPrice && Object.keys(r.union.fixedFruitPrice).map(s => {
                                return <span className="pr-2" key={s}>{s}: {r.union.fixedFruitPrice[s]}</span>
                            })}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </section>
            {hasVillagePermission && <section>
                <h3>Add New Village</h3>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Village Name</label>
                    <input type="text" className="form-control"
                           onChange={e => setNewVillageName(e.target.value)}
                           value={newVillageName}/>
                </div>
                <div className="mb-3">
                    <button className="btn btn-outline-primary"
                            disabled={!newVillageName}
                            onClick={createNewVillage}>Add Village
                    </button>
                </div>
            </section>}
            <section>
                <h3>Villages</h3>
                <table>
                    <thead>
                    <tr>
                        <th style={headerStyle}>ID</th>
                        <th style={headerStyle}>Name</th>
                        <th style={headerStyle}>Region</th>
                        <th style={headerStyle}>Last harvest</th>
                        {harvestTypes.map(harvestType => {
                            return <th key={harvestType.value} style={headerStyle}>{harvestType.label} price</th>
                        })}
                    </tr>
                    </thead>
                    {data
                        .map(datum => {
                            return (
                                <tr key={datum._id}>
                                    <td style={labelStyle}>{datum._id}</td>
                                    <td style={labelStyle}>
                                        <Link to={`/villages/${datum._id}`}>{datum.name}</Link></td>
                                    <td style={labelStyle}>
                                        {hasVillagePermission ? <select className="form-control"
                                                                        onChange={e => updateRegion(datum, e.target.value)}
                                                                        value={datum.region}>
                                            <option value={null}>Unset</option>
                                            {regions.map(r => <option key={r.name} value={r.name}>{r.name}</option>)}
                                        </select> : datum.region}
                                    </td>
                                    <td style={valueStyle}>{datum.lastHarvest ? moment(datum.lastHarvest).format('DD MMM YYYY') : ''}</td>
                                    {harvestTypes.map(harvestType => {
                                        return <td key={harvestType.value}
                                                   style={valueStyle}>{datum.prices && datum.prices[harvestType.value]}</td>
                                    })}
                                </tr>
                            )
                        })}
                </table>
            </section>
        </>
    )
}
