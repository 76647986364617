import React, { useEffect, useState } from 'react'
import moment from 'moment'
import numeral from 'numeral'
import { useNavigate } from 'react-router-dom'

const urlSearchParams = new URLSearchParams(window.location.search)

const now = moment().format('YYYY-MM-DD')
const valueStyle = { textAlign: 'right', width: '150px', borderBottom: '1px solid black' }
const headerStyle = { textAlign: 'right', width: '150px', borderBottom: '1px solid black' }
const labelStyle = { textAlign: 'right', width: '180px', fontWeight: 'bold', borderBottom: '1px solid black' }
const suffixStyle = { textAlign: 'left', width: '30px', borderBottom: '1px solid black' }

const OilStatistics = ({ fromDate, toDate, label }) => {
    const [statistics, setStatistics] = useState({});

    useEffect(() => {
        fetch(`/api/oil/harvests/statistics?fromDate=${fromDate}&toDate=${toDate}`)
            .then(response => response.json())
            .then(setStatistics)
    }, [fromDate, toDate]);

    const stats = [
        {
            label: 'Total Harvested',
            value: statistics.totalHarvested / 1000,
            suffix: 'kg'
        },
        {
            label: 'Total Cost',
            value: statistics.totalCost,
            suffix: 'Tsh'
        },
        {
            label: 'Total Sacks',
            value: statistics.totalSacks,
        },
        {
            label: 'Total Transport Cost',
            value: statistics.transportCost,
            suffix: 'Tsh'
        }
    ];
    return (<div style={{width: "30%", paddingRight: '10px'}}>
        <h2>{label}</h2>
        <table>
            <tbody>
            {
                stats.map((stat, index) => {
                    return (
                        <tr key={index}>
                            <td style={labelStyle}>{stat.label}</td>
                            <td style={valueStyle}>{numeral(stat.value).format('0,0')}</td>
                            <td style={suffixStyle}>{stat.suffix}</td>
                        </tr>
                    )
                })
            }
            </tbody>
        </table>
    </div>);
}

export const OilSacks = () => {

    const [harvests, setHarvests] = useState([]);

    const navigate = useNavigate()
    const [fromDate, setFromDate] = useState(urlSearchParams.get('fromDate') || now)
    const [toDate, setToDate] = useState(urlSearchParams.get('toDate') || now);

    useEffect(() => {
        const refresh = async () => {
            const params = [];
            if (toDate) {
                params.push(`toDate=${toDate}`)
            }
            if (fromDate) {
                params.push(`fromDate=${fromDate}`)
            }
            navigate(`/oil?${params.join('&')}`)
            const response = await fetch(`/api/oil/harvests?${params.join('&')}`)
            setHarvests(await response.json())
        }
        refresh()
    }, [fromDate, navigate, toDate])

    return <section className="container-fluid">
        <div className="row m-5">
            <OilStatistics label="Week" fromDate={moment().subtract(1, 'week').format("YYYY-MM-DD")} toDate={now}/>
            <OilStatistics label="Month" fromDate={moment().subtract(1, 'month').format("YYYY-MM-DD")} toDate={now}/>
            <OilStatistics label="Year" fromDate={moment().subtract(1, 'year').format("YYYY-MM-DD")} toDate={now}/>
        </div>
        <div className="row">
            <div className="col-sm-12">
                <label>
                    <span>From Date</span>
                    <input className="form-control"
                           type="date"
                           value={fromDate}
                           onChange={e => setFromDate(e.target.value)}/>
                </label>
                <label>
                    <span>To Date</span>
                    <input className="form-control"
                           type="date"
                           value={toDate}
                           onChange={e => setToDate(e.target.value)}/>
                </label></div>
        </div>
        <table className="table table-striped table-hover">
            <thead>
            <tr>
                <th style={headerStyle}>Date</th>
                <th style={headerStyle}>Region</th>
                <th style={headerStyle}>Truck</th>
                <th style={headerStyle}>Total Harvested (kg)</th>
                <th style={headerStyle}>Total Sacks</th>
                <th style={headerStyle}>Type</th>
                <th style={headerStyle}>Price type</th>
                <th style={headerStyle}>Price</th>
                <th style={headerStyle}>Fruit cost</th>
                <th style={headerStyle}>Transport cost</th>
                <th style={headerStyle}>Total cost</th>
                <th style={headerStyle}>Contact name</th>
                <th style={headerStyle}/>
                <th style={headerStyle}>Contact phone</th>
            </tr>
            </thead>
            <tbody>
            {harvests
                .filter(h => !!h.timestamp)
                .filter(h => !!h.contact)
                .map(harvest => {
                    const fruitCost = harvest.totalPrice
                    const totalCost = fruitCost + harvest.transportCost
                    return (<tr key={harvest._id}>
                        <td style={valueStyle}>{moment(harvest.timestamp).format('YYYY-MM-DD')}</td>
                        <td style={valueStyle}>{harvest.region}</td>
                        <td style={valueStyle}>{harvest.truckNumber}</td>
                        <td style={valueStyle}>{numeral(harvest.totalHarvested / 1000).format('0,0')}</td>
                        <td style={valueStyle}>{harvest.totalSacks}</td>
                        <td style={valueStyle}>{harvest.type}</td>
                        <td style={valueStyle}>{harvest.priceType || 'SACK'}</td>
                        <td style={valueStyle}>{numeral(harvest.pricePerSack || harvest.pricePerKilo).format('0,0')}</td>
                        <td style={valueStyle}>{numeral(fruitCost).format('0,0')}</td>
                        <td style={valueStyle}>{numeral(harvest.transportCost).format('0,0')}</td>
                        <td style={valueStyle}>{numeral(totalCost).format('0,0')}</td>
                        <td style={valueStyle}>{harvest.contact.firstName}</td>
                        <td style={valueStyle}>{harvest.contact.lastName}</td>
                        <td style={valueStyle}>{harvest.contact.phone}</td>
                    </tr>);
                })}
            </tbody>
        </table>
    </section>

};
