import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const valueStyle = { textAlign: 'right', width: '150px', borderBottom: '1px solid black' }
const switchStyle = { textAlign: 'right', width: '50px', borderBottom: '1px solid black' }
const headerStyle = { textAlign: 'right', width: '150px', borderBottom: '1px solid black' }

export const Users = () => {

  const [users, setUsers] = useState([])

  const navigate = useNavigate()

  const refresh = useCallback(async () => {
    navigate(`/users`)
    const response = await fetch(`/api/users`)
    setUsers(await response.json())
  }, [navigate])

  useEffect(refresh, [refresh])

  const setDisabled = async (user, disabled) => {
    await fetch(`/api/users/${user._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'disabled': disabled })
    })
    await refresh()
  }

  return <section className="container-fluid">
    <table className="table table-striped table-hover">
      <thead>
      <tr>
        <th style={headerStyle}>Name</th>
        <th style={headerStyle}>Email</th>
        <th style={headerStyle}>Phone</th>
        <th style={headerStyle}>Permissions</th>
        <th style={headerStyle}>Last login</th>
        <th style={headerStyle}>App version</th>
        <th style={switchStyle}/>
      </tr>
      </thead>
      <tbody>
      {users
        .sort((a, b) => (a.name.localeCompare(b.name)))
        .map(user => {
          const userIsEnabled = !user.disabled
          return (<tr key={user._id}>
            <td style={valueStyle}>{user.name}</td>
            <td style={valueStyle}>{user.email}</td>
            <td style={valueStyle}>{user.phone}</td>
            <td style={valueStyle}>{user.permissions.join(', ')}</td>
            <td style={valueStyle}>{user.lastLogin}</td>
            <td style={valueStyle}>{user.appVersion}</td>
            <td style={switchStyle}>
              <div className="form-check form-switch">
                <input className="form-check-input"
                       checked={userIsEnabled}
                       onChange={e => setDisabled(user, userIsEnabled)}
                       type="checkbox" role="switch"/>
              </div>
            </td>
          </tr>)
        })}
      </tbody>
    </table>
  </section>

}
