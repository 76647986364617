import React, { useCallback, useEffect, useState } from 'react'
import numeral from 'numeral'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { hasPermission } from './permissions'

const now = moment().format('YYYY-MM-DD')
const urlSearchParams = new URLSearchParams(window.location.search)

export const ReceivedHarvests = () => {
  const [data, setData] = useState([])
  const [village, setVillage] = useState('')
  const [received, setReceived] = useState([])
  const navigate = useNavigate()
  const [fromDate, setFromDate] = useState(urlSearchParams.get('fromDate') || now)
  const [toDate, setToDate] = useState(urlSearchParams.get('toDate') || now)
  const [canDelete, setCanDelete] = useState(false)
  const [showMissingOnly, setShowMissingOnly] = useState(false)

  useEffect(() => {
    hasPermission('DELETE_HARVEST').then(setCanDelete)
  }, [setCanDelete])

  const refresh = useCallback(async () => {
    const params = []
    if (toDate) {
      params.push(`toDate=${toDate}`)
    }
    if (fromDate) {
      params.push(`fromDate=${fromDate}`)
    }
    if (village) {
      params.push(`village=${village}`)
    }
    navigate(`/received?${params.join('&')}`)
    const breakdown = await fetch(`/api/fruit/harvests/statistics/villages?${params.join('&')}`)
    setData(await breakdown.json())
    const individual = await fetch(`/api/fruit/received?${params.join('&')}`)
    setReceived(await individual.json())
  }, [village, fromDate, navigate, toDate])

  useEffect(() => {refresh()}, [refresh])

  const labelStyle = { textAlign: 'right', width: '200px', fontWeight: 'bold', borderBottom: '1px solid black' }
  const valueStyle = { textAlign: 'right', width: '150px', borderBottom: '1px solid black' }
  const headerStyle = { textAlign: 'right', width: '150px', borderBottom: '1px solid black' }

  const receivedHeaderRow = received.reduce((a, c) => {
    a.weighedCrates += c.weighedCrates.length
    a.matchedCrates += c.matchedCrates.length
    a.weightDifference += c.weightDifference
    return a
  }, { weighedCrates: 0, matchedCrates: 0, weightDifference: 0 })

  const refreshReceivedFruit = async id => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm('Are you sure you want to refesh (rematch) this received fruit?')
    if (confirmed) {
      const response = await fetch(`/api/fruit/received/${id}/clear-and-refresh`, {
        method: 'POST'
      })
      if (response.ok) {
        alert(`Received fruit deleted: ${id}`)
      } else {
        alert(`Unable to delete received fruit: ${response.statusText}`)
      }
      refresh()
    }
  }

  const deleteReceivedFruit = async id => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm('Are you sure you want to delete this received fruit?')
    if (confirmed) {
      const response = await fetch(`/api/fruit/received/${id}`, {
        method: 'DELETE'
      })
      if (response.ok) {
        alert(`Received fruit deleted: ${id}`)
      } else {
        alert(`Unable to delete received fruit: ${response.statusText}`)
      }
      refresh()
    }
  }

  return (
    <section>
      <h2>Ratio of harvested to received weights per village</h2>
      <small>&lt; 100% means less fruit was received than harvested</small>
      &nbsp;|&nbsp;
      <small>&gt; 100% means more fruit was received than harvested</small>
      <div className="row">
        <div className="col-sm-12 padded">
          <label>
            <span>Hide received crates</span>
            <input className="form-check-input"
                   type="checkbox"
                   value={showMissingOnly}
                   checked={showMissingOnly}
                   onChange={() => setShowMissingOnly(!showMissingOnly)}/>
          </label>
          <label>
            <span>Village #</span>
            <input className="form-control"
                   type="number"
                   value={village}
                   onChange={e => setVillage(e.target.value)}/>
          </label>
          <label>
            <span>From Date</span>
            <input className="form-control"
                   type="date"
                   value={fromDate}
                   onChange={e => setFromDate(e.target.value)}/>
          </label>
          <label>
            <span>To Date</span>
            <input className="form-control"
                   type="date"
                   value={toDate}
                   onChange={e => setToDate(e.target.value)}/>
          </label></div>
      </div>
      <table>
        <thead>
        <tr>
          <th style={headerStyle}>Region</th>
          <th style={headerStyle}>Village</th>
          <th style={headerStyle}>Harvested (kg)</th>
          <th style={headerStyle}>Received (kg)</th>
          <th style={headerStyle}>Missing (kg)</th>
          <th style={headerStyle}>Percentage received</th>
          <th style={headerStyle}>Harvested crates</th>
          <th style={headerStyle}>Received crates</th>
          <th style={headerStyle}>Missing crates</th>
        </tr>
        </thead>
        {data
          .sort((a, b) => {
            const aRatio = a.totalHarvested - a.totalReceived
            const bRatio = b.totalHarvested - b.totalReceived
            return aRatio - bRatio
          })
          .map(datum => {
            const ratio = (datum.totalReceived / datum.totalHarvested) * 100
            const weightMissing = (datum.totalHarvested - datum.totalReceived)
            return (
              <tr key={datum.village._id}>
                <td style={valueStyle}>{datum.village.region}</td>
                <td style={labelStyle}>
                  <Link to={`/villages/${datum.village._id}?fromDate=${fromDate}&toDate=${toDate}`}>{datum.village.name}</Link>
                </td>
                <td style={valueStyle}>{numeral(datum.totalHarvested / 1000).format('0,0')}</td>
                <td style={valueStyle}>{numeral(datum.totalReceived / 1000).format('0,0')}</td>
                <td style={valueStyle}>{numeral(weightMissing / 1000).format('0,0')}</td>
                <td style={labelStyle}>{numeral(ratio).format('0.00')}%</td>
                <td style={labelStyle}>{datum.totalCrates}</td>
                <td style={labelStyle}>{datum.receivedCrates}</td>
                <td style={labelStyle}>{datum.totalCrates - datum.receivedCrates}</td>
              </tr>
            )
          })}
      </table>
      <table className="table table-striped table-hover">
        <thead>
        <tr>
          <td>Date</td>
          <td>Village</td>
          <td>Weight</td>
          <td>Weighed Crates</td>
          <td>Matched Crates</td>
          <td>Difference (kg)</td>
          <td>Missing</td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td/>
          <td/>
          <td/>
          <td>{receivedHeaderRow.weighedCrates}</td>
          <td>{receivedHeaderRow.matchedCrates}</td>
          <td>{numeral(receivedHeaderRow.weightDifference / 1000).format('0,0.000')}</td>
          <td>{receivedHeaderRow.weighedCrates - receivedHeaderRow.matchedCrates}</td>
        </tr>
        {received
          .filter(r => !showMissingOnly || r.matchedCrates.length === 0)
          .map(r => {
            return <tr key={r._id} className={r.matchedCrates.length === 0 ? 'table-danger' : ''}>
              <td>{moment(r.date).format('MMM DD, YYYY HH:mm')}</td>
              <td>{r.village}</td>
              <td>{numeral(r.weight / 1000).format('0,0.000')}</td>
              <td>{r.weighedCrates[0]}</td>
              <td>{r.matchedCrates.length ? r.matchedCrates[0] : 'NONE'}</td>
              <td>{numeral(r.weightDifference / 1000).format('0,0.000')}</td>
              <td>{r.weighedCrates.length - r.matchedCrates.length}</td>
              <td>{canDelete &&
              <button className="btn btn-warning" onClick={() => refreshReceivedFruit(r._id)}>Refresh</button>}</td>
            <td>{canDelete &&
            <button className="btn btn-danger" onClick={() => deleteReceivedFruit(r._id)}>Delete</button>}</td>
          </tr>
        })}
        </tbody>
      </table>
    </section>
  )
}
