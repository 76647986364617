import React, {useEffect, useState} from 'react'
import GoogleMapReact from 'google-map-react'

const Harvest = ({lat, lng, harvest}) => {
  return <div className='badge badge-info badge-pill'>{harvest.totalHarvested / 1000}kg</div>
}

export const VillageMap = () => {
  const [villages, setVillages] = useState([])
  const [village, setVillage] = useState('000')
  const [harvests, setHarvests] = useState([])

  useEffect(() => {
    const refresh = async () => {
      const response = await fetch('/api/villages')
      setVillages(await response.json())
    }
    refresh()
  }, [])

  useEffect(() => {
    const refresh = async () => {
      const response = await fetch(`/api/fruit/harvests?village=${village}`)
      const json = await response.json()
      setHarvests(json.filter(s => s.sourceType === 'app'))
    }
    refresh()
  }, [village])

  const harvestsWithLocation = harvests.filter(harvest => !!harvest.location)
  const harvestsWithoutLocation = harvests.filter(harvest => !harvest.location)
  const usersWithLocationTurnedOff = Array.from(harvestsWithoutLocation.reduce((a, c) => {
    a.add(c.source)
    return a
  }, new Set()))
  const center = harvestsWithLocation.length ? (harvestsWithLocation[0].location.coordinates) : [33.538448797261836, -9.169063842925969]
  return (
      <div>
        <div style={{padding: '5px'}}>
          <select value={village} onChange={e => setVillage(e.target.value)}>
            {villages.map(v => <option key={v._id} value={v._id}>{v.name}</option>)}
          </select>
          &nbsp;
          Total Harvests: <span className='badge badge-info'>{harvests.length}</span>
          &nbsp;
          GPS Missing: <span className='badge badge-warning'>{harvestsWithoutLocation.length}</span>
        </div>
        <div style={{height: '50vh', width: '100%'}}>
          <GoogleMapReact
              bootstrapURLKeys={{key: 'AIzaSyCVfznYo0wbEmJ8QEOKK3xjPvO75VseDqg'}}
              center={{lat: center[1], lng: center[0]}}
              defaultZoom={11}
          >
            {harvestsWithLocation.map(harvest => (<Harvest
                key={harvest._id}
                lat={harvest.location.coordinates[1]}
                lng={harvest.location.coordinates[0]} harvest={harvest}
            />))}
          </GoogleMapReact>
        </div>
        {usersWithLocationTurnedOff.length && (<><h2>Users with location turned off</h2>
          <ol>
            {usersWithLocationTurnedOff.map(user => {
              return <li key={user}>{user}</li>
            })}
          </ol>
        </>)}
      </div>
  )
}
