import React, {useEffect, useState} from 'react';
import {Loader} from "react-bootstrap-typeahead";
import moment from "moment";

const now = moment().format("YYYY-MM-DD");

export const Banking = () => {

    const [fileLoading, setFileLoading] = useState(false);
    const [loading, setLoading] = useState({});
    const [harvests, setHarvests] = useState([]);
    const [formData, setFormData] = useState(null);
    const [fromDate, setFromDate] = useState(now);
    const [toDate, setToDate] = useState(now);
    const [villages, setVillages] = useState([]);
    const [growers, setGrowers] = useState([]);
    const [village, setVillage] = useState(null);

    useEffect(() => {
        fetch('/api/villages')
            .then(response => response.json())
            .then(setVillages);
    }, [setVillages])
    useEffect(() => {
        fetch('/api/growers')
            .then(response => response.json())
            .then(setGrowers);
    }, [setGrowers])

    const onFileChange = e => {
        const data = new FormData()
        data.append('file', e.target.files[0])
        setFormData(data);
    }
    const loadFile = async () => {
        setFileLoading(true);
        try {
            const response = await fetch(`/api/finance/transactions`, {
                method: 'POST',
                body: formData
            });
            if (response.ok) {
                const transactions = await response.json();
                setHarvests(transactions);
            } else {
                alert(`Error trying to load transactions: ${response.statusText}`)
            }
        } catch (e) {
            alert(`Error trying to load transactions: ${e.message}`)
        }
        setFileLoading(false);
    };

    const valueStyle = {textAlign: 'right', width: '150px', borderBottom: '1px solid black'}
    const headerStyle = {textAlign: 'right', width: '150px', borderBottom: '1px solid black'}
    const wideHeaderStyle = {textAlign: 'right', width: '200px', borderBottom: '1px solid black'}

    const updateTransaction = (oldTxn, newTxn) => {
            const newHarvests = harvests.map(h => {
                if (h === oldTxn) {
                    return newTxn;
                }
                return h;
            });
            setHarvests(newHarvests);
        }

        const markAsPaid = async (transaction) => {
            const {transactionRefNumber, contractRefNumber, harvest} = transaction;
            const id = harvest._id;
            setLoading({...loading, [id]: true})
            try {
                const response = await fetch(`/api/fruit/harvests/${id}/payment-status`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        paymentStatus: 'PAID',
                        transactionRefNumber,
                        contractRefNumber
                    })
                });
                const newHarvest = await response.json();
                updateTransaction(transaction, {...transaction, harvest: newHarvest});
            } catch (e) {
                updateTransaction(transaction, {...transaction, status: e.message});
            }
            setLoading({...loading, [id]: false})
        };

    const manuallyAssignHarvest = async transaction => {
        const harvestId = prompt('Please enter harvest ID to manually assign');
        const response = await fetch(`/api/fruit/harvests/${harvestId}`)
        if (response.ok) {
            const harvest = await response.json();
            updateTransaction(transaction, {...transaction, harvest});
        }
    };

    const findMatchingHarvest = async transaction => {
        const response = await fetch(`/api/fruit/harvests/search?grower=${transaction.grower._id}`)
        if (response.ok) {
            const harvests = await response.json();
            console.log(`Found ${harvests.length} harvests`);
            const valueFromBank = transaction.record.split(',')[1];
            console.log(`Searching for net price ${valueFromBank}`);
            return harvests.find(s => `${s.netPrice}` === valueFromBank || `${Math.floor(s.netPrice * 0.98)}` === valueFromBank);
        } else {
            return null;
        }
    };

    const manuallyAssignGrower = async transaction => {

        const growerName = transaction.record.split(',')[0];
        const matchingGrowers = growers.filter(s => s.displayName === growerName);
        let grower = matchingGrowers.length === 1 ? matchingGrowers[0] : null;
        if (!grower) {
            const id = prompt('Please enter grower ID to manually assign');
            const response = await fetch(`/api/growers/${id}`)
            if (response.ok) {
                grower = await response.json();
            } else {
                alert(`No grower found for ${growerName} or ${id}`)
                return;
            }
        }
        const newTxn = {...transaction, grower};
        const harvest = await findMatchingHarvest(newTxn);
        updateTransaction(transaction, {...newTxn, harvest})
    };

    const unpaidTransactions = harvests.filter(({harvest}) => harvest && harvest.paymentStatus === 'UNPAID');

    const markAllAsPaid = async () => {
        // eslint-disable-next-line no-restricted-globals
        const confirmation = confirm(`Accept all ${unpaidTransactions.length} transactions?`)
        if (confirmation) {
            for (const transaction of unpaidTransactions) {
                await markAsPaid(transaction);
            }
            await loadFile();
        }
    }

    const generatePendingPayments = async () => {
        const response = await fetch(`/api/reports/BANK_REPORT?fromDate=${fromDate}&toDate=${toDate}&${village ? 'village=' + village : ''}`,
            {method: 'POST'})
        if (response.ok) {
            alert('Report sent to inbox')
        } else {
                alert('Failed generating report')
            }
        }

        return <div className="container-fluid">
            <h1>Outgrower payments</h1>
            <div className="row">
                <div className="col-sm-4">
                    <label>
                        Select Bulk Payment file:
                        <input type="file" className="form-control" onChange={onFileChange}/>
                    </label>
                    <label>
                        {fileLoading ? <Loader label="Loading harvests..."/> :
                            <button className="btn btn-primary" disabled={!formData} onClick={loadFile}>Submit</button>}
                    </label>
                </div>
                <div className="col-sm-4">
                    <button
                        className="btn btn-danger"
                        disabled={!harvests.length}
                        onClick={markAllAsPaid}>Accept {unpaidTransactions.length} transactions
                    </button>
                </div>
                <div className="col-sm-4">
                    <select value={village} onChange={e => setVillage(e.target.value)}>
                        <option value={null}>All villages</option>
                        {villages.map(v => (<option key={v._id} value={v._id}>{v.name}</option>))}
                    </select>
                    <input type="date" value={fromDate} onChange={e => setFromDate(e.target.value)}/>
                    <input type="date" value={toDate} onChange={e => setToDate(e.target.value)}/>
                    <button className="btn btn-outline-primary" onClick={generatePendingPayments}>
                        Download unpaid harvests
                    </button>
                </div>
            </div>
            <table className="table table-striped table-hover">
                <thead>
                <tr>
                    <th style={wideHeaderStyle}>Beneficiary</th>
                    <th style={headerStyle}>Amount</th>
                    <th style={headerStyle}>Account</th>
                    <th style={headerStyle}>E-BANKING REF NO</th>
                    <th style={headerStyle}>CONTRACT REF NO</th>
                    <th style={wideHeaderStyle}>Harvest</th>
                    <th style={headerStyle}>Status</th>
                    <th style={headerStyle}>Action</th>
                </tr>
                </thead>
                <tbody>
                {harvests
                    .map((transaction, index) => {
                        const {record, transactionRefNumber, contractRefNumber, grower, harvest, status} = transaction;
                        const strings = record.split(",");
                        const id = harvest && harvest._id;
                        const done = harvest && harvest.paymentStatus === 'PAID' && harvest.transactionRefNumber === transactionRefNumber;
                        const action = harvest ? (loading[id] ? <Loader/> :
                            (!done ?
                                <button className="btn btn-outline-secondary"
                                        onClick={() => markAsPaid(transaction)}>Accept</button> :
                                harvest.paymentStatus)) : null;
                        const growerName = strings[0];
                        return (<tr key={index} className={done ? 'table-success' : ''}>
                            <td style={valueStyle}>{growerName} {grower ? ` ✅` : <React.Fragment>
                                <button className="btn btn-outline-secondary"
                                        title="Click to choose grower"
                                        onClick={() => manuallyAssignGrower(transaction)}>Fix
                                </button>
                                ❌</React.Fragment>}</td>
                            <td style={valueStyle}>{strings[1]}</td>
                            <td style={valueStyle}>{strings[2]}</td>
                            <td style={valueStyle}>{transactionRefNumber}</td>
                            <td style={valueStyle}>{contractRefNumber}</td>
                            <td style={valueStyle}>{harvest ? `${harvest.code} ✅` : <React.Fragment>
                                <button className="btn btn-outline-secondary"
                                        onClick={() => manuallyAssignHarvest(transaction)}>Assign
                                </button>
                                ❌</React.Fragment>}</td>
                            <td style={valueStyle}>{status}</td>
                            <td style={valueStyle}>
                                {action}
                            </td>
                        </tr>);
                    })}
                </tbody>
            </table>
        </div>
    }
;
