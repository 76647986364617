import React, { useCallback, useEffect, useState } from 'react'
import numeral from 'numeral'

export const ChangePrice = ({ harvest, onClose }) => {
  const [price, setPrice] = useState(harvest.pricePerKilo)

  const changePrice = async (harvest, price) => {
    if (price >= 0) {
      const result = await fetch(`/api/fruit/harvests/${harvest._id}/price`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          price
        })
      })
      if (!result.ok) {
        alert(`Unable to change harvest price: ${result.statusText}`)
      } else {
        onClose()
      }
    }
  }

  return (<>
    <div className="m-2">
      <div className="row">
        <label>
          <span>Change price of harvest from: {harvest.pricePerKilo}</span>
        </label>
      </div>
      <label>
        <span>Price (Tsh per kilo)</span>
        <input value={price} className="form-control" onChange={e => setPrice(e.target.value)}/>
      </label>
      <button type="button" onClick={() => changePrice(harvest, price)} className="btn btn-primary">Submit</button>
      <button type="button" onClick={() => onClose()} className="btn btn-outline-info m-2">Cancel</button>
    </div>
  </>)
}

export const GradeHarvest = ({ harvest, onClose }) => {
  const [type, setType] = useState('')
  const [price, setPrice] = useState(0)
  const [size, setSize] = useState(0)
  const [grades, setGrades] = useState(null)
  const [weight, setWeight] = useState(0)
  const [harvestTypes, setHarvestTypes] = useState([])
  useEffect(() => {
    fetch('/api/fruit/harvests/types')
      .then(response => response.json())
      // Ignore ungraded harvests!
      .then(types => types.filter(s => s.value !== 'UNGRADED'))
      .then(setHarvestTypes)
  }, [setHarvestTypes])

  const refresh = useCallback((signal) => {
    fetch(`/api/fruit/harvests/${harvest._id}/grade`, { signal })
      .then(response => response.json())
      .then(setGrades)
  }, [harvest._id])

  useEffect(() => {
    const abort = new AbortController()
    refresh(abort.signal)
    return () => {
      abort.abort()
    }
  }, [refresh])

  const gradeHarvest = async (harvest, type, size, gradeWeight, gradePrice) => {
    if (gradeWeight > 0 && gradePrice >= 0) {
      const result = await fetch(`/api/fruit/harvests/${harvest._id}/grade`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          type,
          size,
          weight: gradeWeight * 1000,
          price: gradePrice
        })
      })
      if (!result.ok) {
        alert(`Unable to grade harvest: ${result.statusText}`)
      } else {
        await refresh()
      }
    }
  }

  const finishGrading = async harvest => {
    const result = await fetch(`/api/fruit/harvests/${harvest._id}/grade`, {
      method: 'DELETE'
    })
    if (!result.ok) {
      alert(`Unable to complete grading harvest: ${result.statusText}`)
    } else {
      onClose()
    }
  }

  return (<>
    <div className="m-2">
      <div className="row">
        <div className="col">
          <div className="h4">
            <span>Outgrower: {harvest.grower}</span>
          </div>
        </div>
        <div className="col">
          <div className="h4">
            <span>Ungraded Total (KG): {numeral(harvest.totalHarvested / 1000).format('0,0.000')}kg</span>
          </div>
        </div>
        <div className="col">
          <div className="h4">
            <span>Crates {harvest.totalCrates}</span>
          </div>
        </div>
      </div>
      <table className="table table-striped">
        <thead>
        <tr>
          <th>ITEM</th>
          <th>PRICE</th>
          <th>KG</th>
          <th>Total (TSH)</th>
        </tr>
        </thead>
        <tbody>
        {grades && !!grades.length && grades.map((grade) => {
          return <tr key={grade._id}>
            <td>{grade.type === 'REJECTS' ? 'Reject' : grade.size}</td>
            <td>{grade.pricePerKilo}</td>
            <td>{grade.weightInKilos}</td>
            <td>{numeral(grade.totalPrice).format('0,0')}</td>
          </tr>
        })}
        {grades && !grades.length && (<span>No grading done yet</span>)}
        </tbody>
        {grades && (<tfoot>
        <tr>
          <td style={{ fontWeight: 'bold' }}>TOTAL</td>
          <td></td>
          <td style={{ fontWeight: 'bold' }}>{numeral(grades.map(g => g.totalHarvested).reduce((a, c) => a + c, 0) / 1000).format('0,0.000')}</td>
          <td style={{ fontWeight: 'bold' }}>{numeral(grades.map(g => g.totalPrice).reduce((a, c) => a + c, 0)).format('0,0')}</td>
        </tr>
        </tfoot>)}
      </table>
      <h3>Add new grading</h3>
      <label>
        <span>Type</span>
        <select className="form-select"
                onChange={e => setType(e.target.value)}>
          <option value={''}>Select type...</option>
          {harvestTypes.map(v => (<option key={v.value} value={v.value}>{v.label}</option>))}
        </select>
      </label>
      {type !== 'REJECTS' && (<label>
        <span>Size</span>
        <input value={size} type="number" className="form-control" onChange={e => setSize(Number(e.target.value))}/>
      </label>)}
      <label>
        <span>Price (Tsh per kilo)</span>
        <input value={price} className="form-control" onChange={e => setPrice(e.target.value)}/>
      </label>
      <label>
        <span>Weight (kg)</span>
        <input value={weight} className="form-control" onChange={e => setWeight(e.target.value)}/>
      </label>
      <button type="button"
              onClick={() => gradeHarvest(harvest, type, size, weight, price)}
              className="btn btn-primary">Submit
      </button>
      <button type="button" onClick={() => onClose()} className="btn btn-outline-info m-2">Cancel</button>
      <br/><br/>
      Once the grading is completed, click to clear down any left over kg:
      <button type="button" className="btn btn-danger m-2" onClick={() => finishGrading(harvest)}>Grading
        complete</button>
    </div>
  </>)

}
